import React from 'react';
import { graphql } from 'gatsby';

import '../../fragments.ts';
import backgroundLogo from '../../images/backgroundLogo.svg';
import backgroundLogoInverted from '../../images/backgroundLogoInverted.svg';
import { clsx, wrapCurlyBracedInSpan, wrapSquareBracketedWithEm } from '../../utils/utils';

import * as styles from './ListModule.module.scss';
import { replaceNewLinesWithBr } from '../../utils/sanity';

export const ListModuleFragment = graphql`
  fragment ListModule on SanityLpViralListModule {
    isANumberedList
    title
    introText
    items {
      title
      text
    }
    backgroundLogos {
      withBackgroundLogos
      backgroundLogosPosition
    }
    withLessMarginTop
  }
`;

export type ListModuleProps = {
  isANumberedList: boolean;
  title: string;
  introText?: string;
  items: Array<{
    title: string;
    text?: string;
  }>;
  sideImage?: {
    asset: {
      url: string;
    };
  };
  backgroundLogos: {
    withBackgroundLogos?: boolean;
    backgroundLogosPosition?: 'largerLeftSmallerRight' | 'smallerLeftLargerRight';
  };
  withLessMarginTop?: boolean;
  id?: {
    current: string;
  };
  darkTheme?: boolean;
};

const ListModule = ({
  id,
  isANumberedList,
  title,
  introText,
  items,
  sideImage,
  backgroundLogos,
  withLessMarginTop,
  darkTheme,
}: ListModuleProps): React.ReactElement => {
  return (
    <section
      id={id?.current || ''}
      className={clsx(
        styles.section,
        withLessMarginTop && styles.withLessMarginTop,
        darkTheme && styles.lightText,
      )}
    >
      {backgroundLogos &&
        backgroundLogos.withBackgroundLogos &&
        backgroundLogos.backgroundLogosPosition === 'largerLeftSmallerRight' && (
          <img src={backgroundLogo} className={styles.backgroundLogo} alt="" />
        )}
      {backgroundLogos &&
        backgroundLogos.withBackgroundLogos &&
        backgroundLogos.backgroundLogosPosition === 'smallerLeftLargerRight' && (
          <img src={backgroundLogoInverted} className={styles.backgroundLogo} alt="" />
        )}
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <h3 className={styles.title}>
            {replaceNewLinesWithBr(title, str =>
              wrapSquareBracketedWithEm(str, wrapCurlyBracedInSpan),
            )}
          </h3>
          {introText && (
            <p className={styles.introText}>
              {wrapSquareBracketedWithEm(introText, wrapCurlyBracedInSpan)}
            </p>
          )}
          {isANumberedList && sideImage?.asset.url ? (
            <div className={styles.itemsAndSideImage}>
              <div className={clsx(styles.items, isANumberedList && styles.numberedList)}>
                {items.map((item, i) => (
                  <div
                    key={i}
                    className={clsx(styles.item, isANumberedList && styles.numberedList)}
                  >
                    {isANumberedList && (
                      <div className={clsx(styles.numberContainer)}>
                        <span className={styles.number}>{i + 1}</span>
                      </div>
                    )}
                    <div className={styles.textContainer}>
                      <p className={clsx(styles.itemTitle, isANumberedList && styles.numberedList)}>
                        {item.title}
                      </p>
                      {item.text && <p className={styles.itemText}>{item.text}</p>}
                    </div>
                    {!isANumberedList && <div className={styles.listDivider}></div>}
                  </div>
                ))}
              </div>
              <div className={styles.sideImage}>
                <div className={styles.overlay}></div>
                <img src={sideImage.asset.url} alt="" />
              </div>
            </div>
          ) : (
            <div className={clsx(styles.items, isANumberedList && styles.numberedList)}>
              {items.map((item, i) => (
                <div key={i} className={clsx(styles.item, isANumberedList && styles.numberedList)}>
                  {isANumberedList && (
                    <div className={clsx(styles.numberContainer)}>
                      <span className={styles.number}>{i + 1}</span>
                    </div>
                  )}
                  <div className={styles.textContainer}>
                    <p className={clsx(styles.itemTitle, isANumberedList && styles.numberedList)}>
                      {item.title}
                    </p>
                    {item.text && <p className={styles.itemText}>{item.text}</p>}
                  </div>
                  {!isANumberedList && <div className={styles.listDivider}></div>}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ListModule;
