// extracted by mini-css-extract-plugin
export var playIcon = "VideoSection-module--playIcon--16998";
export var section = "VideoSection-module--section--55164";
export var text = "VideoSection-module--text--ad4c7";
export var textStyleLargeTitle = "VideoSection-module--text-style-large-title--57195";
export var textStyleLargestTitle = "VideoSection-module--text-style-largest-title--88bbe";
export var textStyleMediumTitle = "VideoSection-module--text-style-medium-title--183c9";
export var textStyleSmallTitle = "VideoSection-module--text-style-small-title--f518e";
export var title = "VideoSection-module--title--9acc9";
export var video = "VideoSection-module--video--5fb70";
export var videoContainer = "VideoSection-module--videoContainer--e3482";