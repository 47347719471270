// extracted by mini-css-extract-plugin
export var container = "call-booked-module--container--2ca55";
export var footer = "call-booked-module--footer--cd281";
export var header = "call-booked-module--header--357ff";
export var logoContainer = "call-booked-module--logoContainer--62c36";
export var page = "call-booked-module--page--812bc";
export var textStyleLargeTitle = "call-booked-module--text-style-large-title--cf755";
export var textStyleLargestTitle = "call-booked-module--text-style-largest-title--612fc";
export var textStyleMediumTitle = "call-booked-module--text-style-medium-title--cb455";
export var textStyleSmallTitle = "call-booked-module--text-style-small-title--162e1";
export var title = "call-booked-module--title--3028f";
export var topic = "call-booked-module--topic--95918";
export var videoSection = "call-booked-module--videoSection--ebf1c";