import React from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx, wrapSquareBracketedWithEm } from '../../utils/utils';

import * as styles from './VideoSection.module.scss';

export type VideoSectionProps = {
  videoUrl: string;
  title?: string;
  text?: string;
  className?: string;
};

const VideoSection = ({
  videoUrl,
  title,
  text,
  className,
}: VideoSectionProps): React.ReactElement => {
  return (
    <section id="video" className={clsx(styles.section, className)}>
      {title && <h2 className={styles.title}>{wrapSquareBracketedWithEm(title)}</h2>}
      {text && <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>}
      <div className={styles.videoContainer}>
        <iframe
          src={videoUrl + '?autoPlay=false&playerColor=da0b0b&playButton=true'}
          title="Hook Point video"
          allow="fullscreen"
          allowFullScreen
          // allowTransparency
          frameBorder="0"
          scrolling="no"
          className={styles.video}
        ></iframe>
      </div>
    </section>
  );
};

export default VideoSection;
