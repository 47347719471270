import React from 'react';
import PageSEO from '../../components/PageSEO';
import { LocalizedSEO } from '../../fragments';
import hookPointLogo from '../../images/hookPoint.svg';
import hookPointLogoBlack from '../../images/hookPointBlack.svg';

import { graphql } from 'gatsby';
import Footer from '../../components/Footer';
import { clsx } from '../../utils/utils';

import ListModule from '../../components/modules/ListModule';
import VideoSection from '../../components/modules/VideoSection';
import { useGlobalState } from '../../state/globalStateContext';
import {
  useActivateGoogleOptimize,
  useStoreReferrerOnLoad,
  useStoreUTMParamsOnLoad,
} from '../../utils/hooks';
import * as styles from './call-booked.module.scss';

export const query = graphql`
  {
    sanityLpViralCallBookedPage {
      topic
      title
      videoSection {
        text
        videoUrl
      }
      listSection {
        isANumberedList
        title
        id {
          current
        }
        introText
        sideImage {
          asset {
            url
          }
        }
        items {
          title
          text
        }
        backgroundLogos {
          withBackgroundLogos
          backgroundLogosPosition
        }
        withLessMarginTop
      }

      seo {
        ...SEO
      }
    }
  }
`;

interface CallBookedPageProps {
  data: {
    sanityLpViralCallBookedPage: {
      topic: string;
      title: string;
      videoSection: {
        text: string;
        videoUrl: string;
      };
      listSection: {
        isANumberedList: boolean;
        title: string;
        introText?: string;
        sideImage?: {
          asset: {
            url: string;
          };
        };
        items: Array<{
          title: string;
          text?: string;
        }>;
        backgroundLogos: {
          withBackgroundLogos?: boolean;
          backgroundLogosPosition?: 'largerLeftSmallerRight' | 'smallerLeftLargerRight';
        };
        withLessMarginTop?: boolean;
        id?: {
          current: string;
        };
      };

      seo: LocalizedSEO;
    };
  };
}

const CallBookedPage = ({ data }: CallBookedPageProps): React.ReactElement => {
  const { topic, title, listSection, videoSection, seo } = data.sanityLpViralCallBookedPage;

  const { theme } = useGlobalState();
  const isHidden = useActivateGoogleOptimize() || theme === null;
  useStoreUTMParamsOnLoad();
  useStoreReferrerOnLoad();

  return (
    <main
      style={isHidden ? { opacity: 0 } : undefined}
      className={styles.main}
      data-theme={theme === 'light' ? 'light' : ''}
    >
      <PageSEO defaultTitle="Call Successfully Booked" pageSEO={seo} />

      <div className={styles.page}>
        <header className={styles.header}>
          <div className={styles.logoContainer}>
            <img src={theme === 'light' ? hookPointLogoBlack : hookPointLogo} alt="" />
          </div>
        </header>

        <div className={clsx(styles.container)}>
          <span className={styles.topic}>{topic}</span>
          <h2 className={styles.title}>{title}</h2>
        </div>
        <VideoSection
          className={styles.videoSection}
          videoUrl={videoSection.videoUrl}
          text={videoSection.text}
        ></VideoSection>
        <ListModule
          title={listSection.title}
          isANumberedList={listSection.isANumberedList}
          items={listSection.items}
          sideImage={listSection.sideImage}
          introText={listSection.introText}
          backgroundLogos={listSection.backgroundLogos}
          darkTheme={theme === 'dark'}
          withLessMarginTop
        ></ListModule>

        <div className={styles.footer}>
          <Footer padding="38px 16px" noBackground withPageTheme />
        </div>
      </div>
    </main>
  );
};

export default CallBookedPage;
